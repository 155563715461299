/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            The Idea
          </MKTypography>
          <MKTypography variant="h2" mb={3}>
            How Conflict solve dispute and how it works
          </MKTypography>
          <MKTypography variant="body2">
          Once you’ve opened up a new file the Conflict system contacts the other party and invites them to the Conflict site. After reviewing the file details, both parties have the opportunity to submit compromise proposals based on the individual interests of each side.
          <br /> <br />
After submitting compromise proposal, the system updates the data and waits for the other side to submit their proposal.
<br /> <br />
If the two proposals are compatible with one another, the system will produce the offer of the compromise, which is based on the proposals submitted by both parties and the algorithms base.
<br /> <br />
If the two proposals are not compatible with one another, the system will inform the parties that the proposal failed and will give the two sides another chance to compromise.
<br /> <br />
If after 3 attempts the parties are unable to reach a compromise, the system will automatically suggest a compromise offre using its unique ability to interpret the Machine learning, to examine the proposals submitted by the parties and to provide them with the optimal compromise proposal.
If the parties do not agree to the compromise offered by the system, they will be given an opportunity once again submit 3 compromise proposals.
If a compromise is still not reached the system will once again suggest its own compromise offer, this process will continue for a period of 30 days.
<br /> <br />
<b>After 30 days </b> the file will be closed in the Conflict system. <b>After 60 days</b> the file will delete from the system.
<br /> <br />
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
