/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
// import CoverLayout from "pages/Authentication/components/CoverLayout";
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-rental.jpeg";



async function postData(url = "", data = {}) {
  
  const response = await fetch(url, {
    method: "POST", 
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
     
    },
    redirect: "follow", 
    body: JSON.stringify(data), 
  });
  return response.json();
}

function Cover() {
  const [emailUser, setEmailUser] = useState("");
  const [passer, setPassr] = useState("");
  const [Fullyname, setNames] = useState("");
  const [emailUser2, setPEmailUser2] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [Results, setRes] = useState("");
  const [ShowF1, setshow1] = useState("block");
  const [ShowF2, setshow2] = useState("none");
  const [CodeReg, setConReg] = useState("");
  const [buttonSet,setButton] = useState("block");
 
 
 



  const emails = (event) =>  { 
        setEmailUser(event.target.value)
  }
  const Passr = (event) =>  { 
    setPassr(event.target.value)
  }
  const fullName = (event) =>  {
     setNames(event.target.value)
  }
  const emails2 = (event) =>  { 
      setPEmailUser2(event.target.value)
  }
  const checkHandler = () => {
    setIsChecked(true)
  }
  const CodeRegLog=(event)=>{
setConReg(event.target.value)



  }
  
  const clickRes=()=>{

   
     

    if(Fullyname.length === 0)
{
  setRes("Fill in your user name");
  return;
  
}
if(emailUser.length === 0)
{
  setRes("Fill in your email address");
 return;
}
 if(!emailUser.includes("@"))
   {
    setRes("Email address in not correct");
     return;
   }
   
if(emailUser!==emailUser2)
{ 
setRes("Email Address do not match");
return;
}
if(passer.length === 0)
{ 
setRes("Fill in your password");
return;
}


if(!isChecked)
{
setRes("You have to agree to the Terms and Conditions");
return
}



  setButton("none")
    setRes("Loading...")
  


    postData("https://conflict.law/handlers/lginNCon.ashx", { EmailUser: emailUser,Pascode: passer,FullName:Fullyname}).then((data) => {
      
    if(data.status === 1)
    {
      setshow1("none");
      setshow2("block");
      setRes("");
      setButton("block")
      
    }
    else 
    {
    setRes("The user already exists in the system");
    setButton("block")  
  }
    });
     

  }
  const clickRegFinish =()=>{
    setButton("none")
    setRes("Loading...")
    postData("https://conflict.law/handlers/lginRCon.ashx", { CodeRegIn: CodeReg,EmailUser:emailUser }).then((data) => {
      
    if(data.status === 1)
    {
      window.location.href="https://conflict.law/Arena/home.html";
      
    }
    else
    {
    setRes("The code entered is incorrect");
    setButton("block")
    }
    });
  }


  return (
    <BasicLayout image={bgImage}>
   
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join Conflict today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
    <MKBox p={3} display={ShowF1}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="text" onChange={fullName} label="Full Name" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails} label="Email" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" onChange={emails2} label="Confirm you email" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" onChange={Passr} label="Password" fullWidth />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={checkHandler} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" display={buttonSet} onClick={clickRes} color="dark" fullWidth>
                sign in
              </MKButton>
            </MKBox>            
            <MKBox mt={3} mb={1} textAlign="center">
            <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>

              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
    <MKBox p={3} display={ShowF2}>
      
      <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
              Please update the code sent to you by email, <br/> * Don&apos;t close window<br/><br/>
              </MKTypography>
             
              
            <MKBox mb={2}>
              <MKInput type="number" onChange={CodeRegLog} label="Code" fullWidth />
            </MKBox>
            <MKButton variant="gradient" display={buttonSet} onClick={clickRegFinish} color="dark" fullWidth>
                sign in
              </MKButton>
              <MKTypography variant="button" color="text">
           {Results}
            <br /><br /><hr/>
            </MKTypography>
      </MKBox>
      </Card>
      
    </BasicLayout>

  );
}

export default Cover;
