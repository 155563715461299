
// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import HowItWorks  from "pages/HowItWorks";

import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
// import VirtualRealityPage from "layouts/pages/extra/virtual-reality";
import About from "layouts/pages/company/About";
// Account
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import SignresetPassword from "layouts/authentication/reset-password/cover";
import SignInvatation from "layouts/authentication/sign-in/Invatation"
import Terms from "pages/Terms"
import Privacy from "pages/Privacy";
// Sections
import AboutUs from "layouts/pages/company/about-us";


const routes = [
  
  {
    name: "Menu",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Conflict.law",
        collapse: [
          {
            name: "Home",
            route: "/pages/company/about-us",
            component: <AboutUs />,
          },
          {
            name : "About Us",
            route : "/pages/company/About",
            component: <About />,
          },
          {  
            name: "How It Works",
            route:"/pages/company/HowitWorks",
            component:<HowItWorks/>,

          }
        ],
      },
      {
        name: "Support",
        collapse: [
          {
            name: "Help center",
            route: "/pages/support/help-center",
            component: <HelpCenter />,
          },
          {
            name: "Contact us",
            route: "/pages/support/contact-us",
            component: <ContactUs />,
          },
        
         ],
      },
           
      {
        name: "Terms & Privacy",
        collapse: [
          {
            name: "Terms & Conditions",
            route: "/pages/Terms",
            component: <Terms />,
          },
          {
            name: "Privacy policy",
            route : "/Pages/Policy",
            component:<Privacy />,

          },
        ],
      },
    ],
  },
  {
    name: "account",
    icon: <Icon>contacts</Icon>,
    columns: 2,
    rowsPerColumn: 3,
    collapse: [
      {
        name: "Log In",
        collapse: [
          {
            name: "Sign in",
            route: "/authentication/sign-in/basic",
            component: <SignInBasicPage />,
          },
          {
            name: "forgot password ?",
            route: "/authentication/resetpassword/basic",
            component: <SignresetPassword />,
          },
          
        ],
      },
      {
        name: "Invitation",
        collapse: [
          {
            name: "Sign in",
            route: "/Invitation",
            component: <SignInvatation />,
          },
         
         ],
      },
      {
        name: "New User",
        collapse: [
          {
            name: "Registration",
            route: "/authentication/sign-up",
            component: <SignUpCoverPage />,
          },
         
         ],
      },
      
     
     
    ],
  },

]; 

export default routes;
