/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
            What is Conflict
          </MKTypography>
          <MKTypography variant="body2">
          For the first time on the Internet theres a system that can resolve online financial disputes without intervention from another person or entity.
          <br /><br />
          Conflict is a useful tool and interface for two parties who are entangled in a financial dispute or some other type of Conflict. The purpose of Conflict is to bring the two
           disputing parties together to find an amicable solution while maintaining the individual interests of each party to the dispute by using a unique mechanism for quick resolution –a tool that enables each party to submit a compromise proposal based on their own interests
          <br /> <br />
          The quick resolution mechanism is a unique tool based on algorithms that helps bring the two disputing parties to an amicable solution without the need to involve another person or another entity.
          <br /> <br /> 
         
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
