

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function TermsInfo() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
          Conflict Terms Of Usage Agreement
          </MKTypography>
          <MKTypography variant="body2">
            
Introduction
Conflict Arena (“Conflict”) is an automatic system that offers its users a unique solution for managing and resolving disputes between two parties (“the Service”). 
<br/><br/>
The Terms of Usage below (“the Terms”) apply to the user of the Internet site: www.Conflict.com (hereinafter, “the Site”).  Use of the site and its content, and the various services operating there, indicate your consent to these Terms whether or not you have opened an account on the site, and therefore you are asked to read them carefully.
<br/><br/>
If you do not agree to these Terms you are not permitted to use the Site, its content and its services.
<br/><br/>
If you are acting on behalf of a company you hereby state and undertake that you have the authority to obligate the company and your consent constitutes the company’s consent to these Terms.  In this case the words “you” and “your” will refer and apply to the company.
<br/><br/>
These terms apply to the user of the Site and the content contained therein through any computer or other communications device (such as cell phone, hand-held devices, etc.).  Furthermore they apply to the use of the Site, whether by way of the Internet or any other network or other means of communication.
The Terms for using the site are worded in the masculine solely for the sake of convenience, but refer of course, to women as well.
<br/><br/>
<br/><br/>
<u>
Definition of Terms
</u>
<br/><br/>

“Site Content” or “Site Contents” – information of any kind and type, including any verbal, visual, audio, audio-visual content or any other combination of these, as well as their design, processing, editing, distribution and presentation, including (but not limited to): any picture, photograph, illustration, animation, diagram, image, simulation, sample, film clip, audio file and music file; any software, file, computer code, application, format, protocol, database and interface; and any character, mark, symbol and icon, and any members’ content.
“Member/s” – anyone who has completed the registration process as required and created an account in the system as described in Section 6 below.  
“Members’ Content” – any content that a Member adds, uploads, submits or transmits through the Site’s services.  
<br/><br/>

“Quick Compromise Mechanism” – a unique mechanism based on several algorithms that allow two disputing parties to reach a solution through amicable means, in a quick and efficient manner without resorting to the courts.
<br/><br/>
<u>
Changes to the Site and termination of Services 
</u>
<br/><br/>
Conflict reserves the right to change or terminate access to the Site, its Services and these Terms, at any time and without prior notice.  After such termination Conflict will save the Site’s Contents for a reasonable period of time, after which it will be permitted to erase it without backing it up and without any additional notification.  Conflict reserves the right to collect payment for Contents and Services at its discretion and to change other aspects of the Site without prior notice.  These changes may initially cause malfunctions and/or inconvenience, but Conflict is not liable for any suit, claim and/or demand in respect of the said changes and/or malfunctions that may have been caused as a result of these changes.
<br/><br/>
In the event of changes to these Terms, we will publish an update regarding the change on the Site or we will provide you with prior warning of the change.  Additionally, we will update the “date last updated” at the top of this page.  Your continued use of the Site and/or the Services following an update for changes constitutes your consent to the new Terms of Use.  In the event you do not agree to the new Terms, you must stop all activity on the Site.
<br/><br/>
<u>
Age restrictions
</u>
<br/><br/>
The Site and its Services are solely for those aged 18 or older.  Entering and/or using the Site and/or its Service by anyone who is not yet 18 years old is strictly prohibited.  When entering the Site and/or using the Services you hereby state and undertake that you are over age 18.
<br/><br/>
<u>
Using Conflict
</u>
<br/><br/>
The Conflict System offers its Members the Quick Compromise Mechanism in order to find an amicable solution to a dispute between two parties while maintaining the individual interests of each side.  A Member who is interested in trying to resolve the dispute between himself and another party using the Conflict Arena is invited to open a file in the Arena.  After the file has been created the system will contact the other party to the dispute, according to the details provided to Conflict during the registration process and when the file was opened, and will invite him to enter the Arena.  If the other party also chooses to open a file in the system and submit his response, it will be possible for the parties to try and resolve the dispute using the Quick Compromise Mechanism.
<br/><br/>
The Site and the Services it includes provide you with a platform for resolving the dispute using an online response that is quick, cheap and efficient.  The decision to use the Conflict System and/or to accept the resolution it proposes rests with each Member independently and at his sole discretion.  At any stage each Member is entitled to agree to the proposal offered by the System and to advance towards resolving the dispute as part of the Quick Compromise Mechanism, or to refuse the proposal offered and terminate the Service.  The Site is not a party to the dispute between Members and does not influence the decisions made by Members in any way whatsoever.  The Conflict System does not guarantee that all disputes will be resolved through the Site and its Services.  You hereby affirm that neither the Site and/or anyone on its behalf shall be liable in any event of claims by Members and/or any Content of Members and/or any damage caused to any of the Members as a result of their agreement or refusal to accept the solution offered through the Quick Compromise Mechanism.  Use of the Site is at your sole responsibility.
<br/><br/>
<u>
Registering for the Site
</u>
<br/><br/>
In order to use the Sites Content and Services, to upload Content of Members and use the Quick Compromise Mechanism, you must register with the Site by creating an account (“Account”).  After creating an Account you will be considered a Member in the Conflict System.  You hereby undertake to provide the System, at all times, with accurate and up-to-date information, and to complete all of the information required for registration, and to update the information from time to time, as necessary.  Entering incorrect details into the System may prevent you from accessing the Site’s Services and Content.  Conflict reserves the right to suspend and/or close your Account in the event the information and/or Content you provided during the registration process or afterwards are incorrect, incomplete or not updated.  You are solely responsible for saving your password.  You hereby affirm that you will not disclose and/or reveal your password to any third parties and you are solely responsible for any action performed under your Account, whether you have authorized such action or not.  We recommend changing your password from time to time in order to keep it secret and to prevent any abuse of your user name.  You hereby undertake to inform Conflict immediately if you suspect any unauthorized use of your Account.
<br/><br/>
<u>
Payment for Services
</u>
<br/><br/>
Use of Conflict requires payment.  In the event you decide to use the Services available from the Site, you hereby authorize Conflict and/or a third party on its behalf to charge the payment method you provided for the amount quoted for the Service.
<br/><br/>
<u>
Privacy
</u>
<br/><br/>
Conflict respects and maintains its Members privacy.  To receive related information please see Conflict’s privacy policy at:  www.Conflict.com/pageinfo.html?page=policy. 
<br/><br/>
<u>
Ownership rights and intellectual property rights
</u>
<br/><br/>

The Site, its Services and the Content contained therein (except for Members’ Content) is protected under intellectual property rights, trademarks and all laws.  You hereby affirm that you are aware that the Site, its Services and all its Contents are the sole property of Conflict and/or any third party authorized by Conflict for its use.  You undertake not to copy, remove, distribute, change, obscure, transmit or present in public and/or any network, to sell or lease, to compile or create derivatives and/or to make any use of the copyrights or trademarks found on the Site, its services and/or its Contents and/or any part of the aforementioned, whether through you and/or in cooperation with a third party in any manner and/or means, including electronic, optical, mechanical, photographic and recording means, without obtaining prior written consent from Conflict and/or the owners of the aforesaid rights.  All trademarks, service marks, logos, commercial names and any other proprietary marks of Conflict are the property of Conflict and they may not be used in any way, including distribution, copying or use in public whether by your and/or through and/or in cooperation with a third party without Conflict’s explicit prior written consent.
<br/><br/>
The trademarks and/or ads of advertisers on the Site are their sole property and may not be used in any manner without their prior written consent. 
Should you receive such consent, you undertake to avoid any removal, erasure and/or disruption of any notice and/or mark relating to the intellectual property rights, including marks such as the copyrights symbol [©] or the registered trademark symbol [®] included in the content you have been permitted to use.
As stated in Section 11, Conflict does not claim to own its Members Content but when you submit Members’ Content you hereby authorize its submission for publication and that you own the rights, or are legally authorized by the holder of the rights thereto and are permitted to submit it for publication.  By submitting Members’ Content you hereby grant Conflict free, unlimited and international license to use the Content in any way according to Conflict’s sole discretion, including to copy, duplicate, market, distribute, publicize, edit, translate and compile these Contents, provided that this is for the purpose of providing the Service on the Site.
<br/><br/>
<u>
Limited license to use information
</u>
<br/><br/>
Subject to your compliance with these Terms, Conflict grants you limited license, which is neither exclusive nor transferable, to view all of the Members’ Content to which you have been given access, only as part of receiving the Service.  You are not permitted to grant a secondary license to any third party in connection with the rights granted to you under this section.  You hereby undertake not to use, copy, change, adapt, create derivative products based on the Content, distribute, sell, transmit, present in public, are make any other use of the Site, its Services or its Contents, unless you are explicitly permitted to do so in this Terms of Usage Agreement.  To remove all doubt, unless noted in these Terms, Conflict and/or anyone authorized on its behalf will not grant you licenses or rights to use the general Content.
<br/><br/>
<u>
Granting a license by a Member
</u>
<br/><br/>
As part of the way the System operates, each Member shall be entitled to upload and publicize Content on the Site.  By publicizing Members’ Content, through the Site and/or its Service, you hereby grant Conflict irrevocable, international, permanent, irremovable, non-exclusive and transferable license that is free of royalties and includes the right to grant a secondary license, to use, present, copy to transmit only through the Site and as part of supplying the Services on the Site.  Conflict does not claim ownership rights to any Members’ Content, and these terms shall not restrict its right to use the Content you publicized as Members’ Content.
<br/><br/>

You are solely responsible for the Members’ Content that you publicize on the Site.  Therefore, you hereby state and undertake that:  (i) You are the sole owner of all the Members’ Content you publicize through the Site and/or its Services, or that you have the rights, licenses, consents and authorizations required in order to upload the Content and grant Conflict the rights to this Content;  (ii) The Content you upload to the Site and/or Conflict’s use of this Content does not constitute a violation of a patent, copyrights, trademarks, trade secrets, ethical rights or other intellectual property rights, or publication or privacy rights of third parties, or the cause of any violation of any law or relevant regulation.
<br/><br/>
<u>General prohibitions
</u>
<br/><br/>
You hereby agree and undertake not to perform any of the following:  To publish, to upload, to send or transmit any Content whatsoever that: (i) Damages, misinterprets or damages a third party’s patent, copyrights, trademarks, trade secrets, commercial rights or other intellectual property rights, including publication or privacy rights;  (ii) Violates, or encourages behavior that violates any law or relevant regulation, or that could give rise to civil and/or criminal liability;  (iii) Constitutes fraud, that is, is misleading or untrue and is liable to mislead a User and/or Member;  (iv) Is defamatory, obscene, pornographic, vulgar or offensive;  (v) Promotes prejudice, racism, hatred, harassment, injury or wrongful discrimination against any person or group including on the basis of skin color, ethnicity, origin, race, gender, sexual orientation, political viewpoint, etc.;  (vi) Is violent, threatening or encourages violence and/or actions that threaten any person or entity;  (vii) Promotes activities or materials that are illegal or harmful;  (viii) Refers to a minor and/or identifies his personal details and/or his address and ways of contacting him;  (ix) Constitutes libel and/or damages the good name and/or privacy of a person, including Content that enables the personal identification of other people without their having given their consent to this;  or (x) Is of a commercial and/or advertising nature, and any Content the access to which requires a password and the like and is not free to any Internet user;  (xi) Is liable to cause damage and/or harm the Users of Conflict and Internet users in general, including Content that contravenes the standard terms of usage commonly found on the Internet;  (xii) Violates the provision of any law, including the Consumer Protection Law.
<br/><br/>
•	To use, present, visually represent or frame the Site and/or any single element contained on the Site and/or its Services, and/or Conflict’s name and/or any Conflict trademark, logo or other intellectual property, or the design and format of each page or form appearing on a page, without Conflict’s written consent;
<br/><br/>•	To access, disrupt or use the non-public areas of the Site, Conflict’s computer systems or its code;
<br/><br/>•	To attempt to investigate, scrutinize or examine the vulnerability of the system, network or Conflict’s security measures;
<br/><br/>•	To avoid, remove, cancel, damage, decode or bypass in any other way any technological means used by Conflict or any of its suppliers or by a third party (including another User);
<br/><br/>•	To access or search the Site, its Services or Contents and/or to download Content from the Site or its Services by using any engine, software, tool, device or mechanism (including web crawlers, robots, scanners, data mining tools or the like), except for the software and/or search engines offered by Conflict or other Internet browsers that are generally made available by a third party;
<br/><br/>•	To use tags or text that use Conflict’s commercial name, URL address or logo without its express written consent;
<br/><br/>•	To attempt to decode, integrate, disassemble or reverse engineer any software or code used by the Site to provide the Services or general Content;
<br/><br/>•	To interfere, or attempt to interfere, with the access of any User, including sending a virus, causing an overload, flooding or spamming the Site or its Services;
<br/><br/>•	To collect or store personal identifying information from the Site or its Services regarding other Users of the Site or its Services without explicit permission;
<br/><br/>•	To impersonate someone else or falsify your connection with another person or entity;
<br/><br/>•	To violate any law or regulation;
<br/><br/>•	To encourage, assist or enable any person to do any of the aforesaid.
<br/><br/>
These provisions shall not derogate from the other provisions as part of these Terms of Usage and/or online agreements that accompany certain Services on the Site.  Conflict is likely to involve and cooperate with law enforcement authorities in filing lawsuits against Users who violate these Terms.  Conflict has no obligation to monitor your access and/or your use of the Site, its Services and Contents, and/or to review and/or edit any Content, but it shall have the right to do so for the purpose of operating the Site and its Services, in order to ensure your compliance with these Terms or in order to obey the law or the demands of any court, authority or other government body.  Conflict reserves the right, at any time and without prior warning, to remove or terminate access to any Content that Conflict, at its sole discretion, considers to be Content that violates these Terms or harms the Site or its Services.
<br/><br/>
<u>
Copyrights policy
</u>
<br/><br/>
Conflict complies with the Copyrights Law and expects Conflict’s Users to do the same.  Conflict’s policy is to terminate the membership of Members and Account owners who damage the rights of copyrights holders or those who Conflict believes are violating such rights.
<br/><br/>
<u>
Links on Conflict’s Site
</u>
<br/><br/>
The Site or the Services included on the Site might contain links to sites of third parties that are provided solely for your convenience.  You hereby affirm and agree that Conflict is not liable for:  (i) The availability, legality or accuracy of these sites;  (ii) The content, products or services that are part of these sites or available therein;  (iii) Privacy measures and any other aspect regarding these sites.  These links do not indicate any support on the part of Conflict for anything described above.  You are solely responsible for all the risks entailed in using these sites, their content, products or the services available therefrom.  Conflict reserves the right to remove links that have been included in the past and/or not to add new links at its sole discretion.
<br/><br/>
<u>
Commercial Content
</u>
<br/><br/>

The Conflict Site includes, or is liable to include in the future, commercial Content, either as text, audio or pictures, such as commercials and ads that publicize on behalf of advertisers and various Users who are interested in offering for sale and/or advertising a property and/or various services.
<br/><br/>
Conflict is not liable for these contents nor does it write, inspect, edit and/or ensure the content of these ads and/or their legality or accuracy.  Advertisers are solely liable for of the aforementioned contents, including any outcome caused as a result of relying on and/or using them.  Conflict does not recommend and/or encourage the purchase and/or the use of anything described above.
<br/><br/>
<u>
Suspension or termination of an Account
</u><br/><br/>
In the event that you violate one of these Terms, Conflict reserves the right to suspend or terminate your Account at its sole discretion and without prior notice.  Conflict reserves the right to cancel your access to the Site, its Services and Contents at any time, even without cause.  You will remain liable for any amounts that you owe even if Conflict suspends or terminates your Account.  Should you wish to terminate your Account, you may do so by sending an e-mail to:  info@conflict.law.
<br/><br/>
<u>
Limits of liability
</u>
<br/><br/>
The Site, its Services and its collective Contents are provided “as is” and are not necessarily suited to the needs of each person.  Conflict removes any liability in respect of the Contents, their capability, their suitability to individual needs, limitations and/or responses that these Contents may generate.  Using Conflict, therefore, is at your sole responsibility and Conflict shall not be liable for any suit and/or claim in this regard.
Conflict provides legal services, however this does not imply that this system is preferable to other solutions and/or that as a result of using the system you will receive benefits, whether personal, commercial or economic.
<br/><br/>

Conflict removes all liability for the correctness, legality, accuracy, and reliability of the Members’ Content as part of using the Conflict System and the Quick Compromise Mechanism. 
<br/><br/>
Conflict cannot predict the reactions (if any) that your Contents will receive on the site and/or who will respond to them and whether they will have any response.  Conflict shall not be liable to you and/or anyone on your behalf pursuant to the use of your Contents by a third party and/or the responses and/or the identity of those who contact you as a result of you publicizing your Content.
<br/><br/>
Conflict does not make any prior examination regarding Members’ Content and it is the sole responsibility of the Members who publicize it.  You are solely responsible for any decision you make regarding the Contents publicized on the Conflict Site.  Conflict does not guarantee that the Contents and/or the Services of content and service suppliers and of the Site’s members, including third parties, is legal, accurate, complete, correct and/or meets your expectations and your demands.  Conflict bears no liability for the use of these contents and/or your reliance thereon.
<br/><br/>
<u>
Indemnification
</u>
<br/><br/>
You hereby agree to indemnify and protect Conflict, its officers, directors, employees and agents, from and against any lawsuit, undertaking, damages, losses and expenditures, including legal fees and accountant’s fees in any way derived from or in connection with your access and/or your use of the Site, its Services, the Site’s Content, or your violation of these Terms.
Additionally, you will indemnify Conflict and the aforementioned in respect of any lawsuit, claim and/or demand raised by any third party pursuant to Members’ Content that you publicized and/or as a result of connections you made through the Site.
<br/><br/>
<u>Legal jurisdiction</u>
<br/><br/>
The laws of the State of Israel shall apply to the Terms of this Agreement without application of the principles of Conflicts of international law stated therein.  Any dispute arising between the parties, including a dispute regarding the use of the Site and/or the Terms of Usage shall be brought before the Tel Aviv District Court in the State of Israel.
<br/><br/>
These Terms constitute a full and exclusive understanding regarding the Site, its Services and Contents, and these Terms take precedence over any previous understanding, whether verbal or written.
<br/><br/>
You may not assign these Terms without prior written consent from Conflict and any attempt to do so shall be null and void.  Conflict reserves the right to assign and/or transfer these Terms at its sole discretion without restriction.
<br/><br/>
<u>
General
</u>
<br/><br/>
Conflict reserves the right to enforce any of the rights and/or any of the provisions of these Terms.  The waiver of any such right and/or provision shall be valid only if it is made in writing and signed by an authorized representative of Conflict.  Apart from what is written explicitly in these Terms, Conflict reserves the right to any legal remedy in the event of a violation of these Terms.  Should an authorized court of law find any provision of these Terms to be invalid and unenforceable, that provision shall be enforced to the maximum degree permitted and the other provisions of these Terms shall remain in full force.
<br/><br/>
<u>
Contact
</u>
<br/><br/>
Should you have any questions whatsoever in connection with these Terms or any comment, we would be happy to hear your opinion.  You may contact Conflict via e-mail at:  info@conflict.law.

         
         
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TermsInfo;
