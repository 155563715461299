

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PrivacyInfo() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          
          <MKTypography variant="h2" mb={3}>
          Conflict Privacy Policy
          </MKTypography>
          <MKTypography variant="body2">
          <u>
Introduction
</u>
<br/><br/>
The Conflict Arena and every person working on its behalf (“Conflict,” “We,” “Ours,” “Us”) respects your privacy.  This privacy policy explains and outlines the procedures and the way in which we use your information as part of the services available through www.Conflict.com (“the Site,” “the Service”).
By using the Site you hereby affirm and agree to Our privacy procedures set forth herein.  This privacy policy applies only to the information that your supply to Us through the Site.  This privacy policy is updated from time to time.  In the event of any change in these terms, we will publish an update regarding the change on the Site, or we will provide you with advance warning of the change.  Additionally, we will update the “date last updated” at the top of this page.  Your continued use of the Site and/or its Services following the update for changes constitutes your consent to the new terms.  In the event you do not agree to the new terms you must cease all activity on the Site.
<br/><br/>
The Service is not intended for use by those under age 18.  If you are not at least 18 years old you may not register for the Service.
<br/><br/>
The terms used in this Privacy Policy shall have the same meaning as defined in the Terms of Use Agreement available on the web site using the link:  www.Conflict.com/pageinfo.html?page-Terms.  Furthermore, the terms “use,” and “processing” of information include the use of cookies, the transmission of information for statistical or other analysis, and/or the use of information in any manner whatsoever, including but not limited to, collecting, storing, assessing, changing, erasing, using, integrating, publishing and transferring information within the organization or outside.
<br/><br/>
<u>
Collecting and using information
</u><br/><br/>

Our main purposing in collecting information is to provide and improve the Sites  Service and Content, in order to enable Us to Provide the Services and allow the User to easily navigate the Site and enjoy its Services.
<br/><br/>
When you register for a Service as a Member, you are required to provide us with information about yourself that will enable us to identify you individually and may be used to create a link or for identification (“Personal Information”).  This Personal Information includes, but is not limited to, your name, address, telephone number, credit card details and other payment methods, e-mail and your home and business address.  We use your Personal Information in order to provide you with the Service and to manage your needs through the Site.
<br/><br/>

We also collect non-identifying information that you provide as part of the registration process and is used in the Service, for example but not limited to:  Information concerning the dispute you will be submitting through the Service, information relating to your transactions vis-à-vis the Service and your personal preferences (“Non-identifying Information”).
<br/><br/>

Occasionally we will cross-reference this information with your Personal Information in order to provide the Service, to allow you to use of the Quick Compromise Mechanism, and to manage your needs on the Site.
<br/><br/>

Non-identifying Information will be considered part of your Personal Information when it is attached to another means of identification (for example, your postal code combined with your street address) in a manner that allows you to be identified.  When that Non-identifying Information is taken alone, or only in combination with other Non-identifying Information, it is considered as Non-identifying Information.
<br/><br/>

Furthermore, we might cross-reference your Personal Information and/or your Non-identifying Information with information collected from other Conflict users in order to provide you with a better experience, to improve Service quality and value, and to analyze usage of the Site and its Services.
<br/><br/>

Additionally, we might use your Personal Information in order to provide you with marketing and sales promotional material and other information we feel could interest you.  You are entitled to decide at any time that are not interested in receiving these materials.  In this case you can send us notification via e-mail to:  info@conflict.law.
<br/><br/>

When you visit the Site, whether as a Member or a non-registered user, Conflict’s servers save information that your browser sends every time you visit an Internet site (“Log files”).  This data includes, among other things, information such as the address of your computer’s Internet Protocol (IP address), the type of browser you use, the Internet page you visited before you visited our Services, the Service pages you used from our Site and how long you visited each one, access times, and other statistical data.  We use this information in order to monitor and analyze usage of the Site and its Services and for the technical administration of the Services so as to make it more suited to the needs of our Users and visitors to the Site and to improve their experience at the Site.
We do not consider Log Files to be Personal Information, but we might attribute these files to Personal Information and cross-reference it for various purposes, as noted above, with Non-identifying Information.
<br/><br/>
<u>
Session and Cookie files
</u>
<br/><br/>
As with numerous other sites, we use Cookie files in order to collect information.  A Cookie File is a small data file that we send to your computer’s hard drive for registration and monitoring purposes (“Cookie Files”).  We use these files in two ways:  First, to save details of your entries so you can enter the Service again in the future.  Second, we use temporary Session Cookie files to enable certain characteristics of the Site so that we can have a better understanding of your communication with the Site and so that we can monitor usage of the Site and direct Internet traffic vis-à-vis the Service.
<br/><br/>

In contrast with persistent cookie files, temporary Session Cookie files are erased from your computer when you leave the Site and close your browser.  You can stop receiving cookies from sites you visit by changing the definitions of your browser, but then certain parts of the Site might not be available to you as a result of this change.
<br/><br/>
<u>
Sharing and disclosing information
</u>
<br/><br/>
We might share and disclose information as follows:
Disputes:  Should you choose to provide information that is liable to include your Personal Information related to disputes being brought for resolution through the Site, we will share the information regarding the dispute with the other party to the dispute, according to the choices you make on the Site.  Therefore, you are asked to carefully consider the type of information you choose to share with third parties through the Site.
<br/><br/>

Cumulative information and Non-identifying information:  We might share cumulative information that does not include Personal Information, and may also disclose through any other means Non-identifying Information and Log Files with third parties for the purpose of industrial analyses, creating demographic profiles and other purposes.  This information will not include your Personal Information.
<br/><br/>

Service providers:  We are entitled to engage third parties to assist us in implementing the Services, to provide the Service on our behalf, to provide services relating to the Service (for example, but not limited to, services for maintenance, database management, Internet analysis, payment methods; Conflict resolution professionals such as mediators and arbitrators, and to improve the Service) or to assist us in analyzing usage of the Site and its Services.  These third parties have access to your Personal Information but only for the purpose of performing these tasks in our name and they are obligated not to disclose or use this for any other purpose.
<br/><br/>

Law enforcement and compliance:  Conflict cooperates with law enforcement authorities and private bodies for the purpose of law enforcement and compliance.  We will disclose any information about you to government officials, law enforcement authorities or other private bodies, to the extent this is necessary and important and at our sole discretion, in order to respond to claims within the framework of a legal proceeding including, but not limited to:  to protect the property and rights of Conflict and/or a third party, to protect public safety, to prevent or stop activity we feel constitutes and/or is likely to encourage illegal and/or unethical activity.
<br/><br/>

Commercial transfers:  Conflict is entitled to sell, transfer or share in any other manner all of its assets or any part thereof, including your Personal Information, in the event of a merger, acquisition, reorganization, sale of assets or bankruptcy.
<br/><br/>
<u>
Change or removal of Member Content
</u>
<br/><br/>

Members are able to examine, update, correct and erase the Personal Information in the registration profile by contacting us.  Should you choose to erase this information completely your Account will become inactive.  If you are interested in doing so, please contact us via e-mail at:  info@conflict.law, along with your request to remove your personal information from our database.  We will make commercially reasonable efforts to comply with your request as soon as possible.  We have the ability to save an archived copy of your records, as required by law or for the purpose of legitimate business.
<br/><br/>

Should the parties reach an agreement and the Conflict is resolved and/or if it is decided that the parties cannot agree, we will sent the information relating to the Conflict details to the parties involved in the Conflict via the e-mail address given to us.  We will save this information in our database for three (3) months, after which it will be erased.
<br/><br/>
<u>
Security
</u>
Conflict is interested in safeguarding your Personal Information.  We employ reasonable physical and technological security measures and do our utmost to provide good protection for the information saved.  Sensitive Personal Information (such as your credit card number, personal security question, etc.) is not saved on the site, but is sent directly to the Easy Card clearinghouse.  We strive to ensure the security of your information and advise you, in addition to our own efforts, to be aware of the various security threats on the Internet.  If a security breach is discovered on our Site, we will inform you via e-mail and/or a prominent notice on the Site as soon as possible.
<br/><br/>


<u>
International transfers
</u>
<br/><br/>

We might transfer and/or save the Personal Information you give us on local computers outside the State of Israel.  Privacy laws in these locations are liable to obligate us to obtain from you explicit permission for every such transfer.  As a User of the Site and having given us details through the Site you hereby explicitly authorize us to transfer and/or save information in these locations.
<br/><br/>
<u>
Links through Conflict
</u>
<br/><br/>

Our Service includes links to other Internet sites.  If you choose to click on the link of a third party you will be directed to the Internet site of that third party.  The fact that we are linked to that Internet site does not constitute a recommendation or approval and does not represent our cooperation with them, and it does not confirm their privacy policy and/or their information security and/or their procedures in this regard.  We have no control over third party Internet sites and these sites are liable to use their own Cookie Files with your computer to collect data or to ask you for personal information.  These sites have different rules concerning the use and/or disclosure of the personal information you give them, and we recommend that you read their privacy policy.
<br/><br/>
<u>
Contact
</u>
<br/><br/>
Should you have any questions whatsoever in connection with this Policy or any comment, you may contact Conflict via e-mail at:  info@conflict.law.
            
   
         
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PrivacyInfo;
