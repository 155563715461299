// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";

// Otis Kit PRO components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Conflict.law - ODR",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Conflicteam/",
    },
  
  ],
  menus: [
    {
      name: "Conflict.law",
      items: [
        {
          name: "How it works",
          href: "/pages/company/About",
        },
        {
          name: "About us",
          href: "/pages/apps/desktop-app",
        },
        
      ],
    },
    {
      name: "resources",
      items: [
        {
          name: "What is ODR ?",
          href: "https://en.wikipedia.org/wiki/Online_dispute_resolution",
        },
        {
          name: "NCSC",
          href: "https://www.ncsc.org/odr",
        },
        {
          name: "NCTDR",
          href: "https://odr.info/",
        },
      ],
    },
    {
      name: "help & support",
      items: [
        {
          name: "contact us",
          href: "/pages/support/contact-us",
        },
        {
          name: "knowledge center",
          href: "/pages/support/help-center",
        },
        
      ],
    },
    {
      name: "legal",
      items: [
        {
          name: "terms & conditions",
          href: "https://Conflict.law",
        },
        {
          name: "privacy policy",
          href: "https://Conflict.law",
        },
       
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Conflict.Law{" "}
      <MKTypography
        component="a"
        href="https://Conflict.law"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      />
    </MKTypography>
  ),
};
