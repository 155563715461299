/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Images
import macbook from "assets/images/OdrInfo.jpg";

function Newsletter() {
  return ( 
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 12 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">What Is ODR ?</MKTypography>
            <MKTypography variant="body2" color="text" mb={1}>
               Court related Online Dispute Resolution (ODR) is a public facing digital space in which
               parties can convene to resolve their dispute or case. Three essential components differentiate court-related ODR from other forms of technology-supported dispute resolution: The first is that the program operates exclusively online.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
